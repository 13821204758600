<template>
  <div class="text-left">
    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <div v-else>
      <BaseHeader
        :title="`${profile.type_desc || 'User'} Profile`"
      ></BaseHeader>

      <div class="card has-shadow mt-4">
        <div class="card-body">
          <v-row>
            <v-col cols="12" sm="6">
              <div class="media">
                <div class="media-body">
                  <h3 class="mt-0">
                    {{ profile.first_name || "" }} {{ profile.last_name || "" }}
                  </h3>
                  <p class="my-1">
                    <i class="icon-envelope mr-2 text-primary"></i
                    >{{ profile.email || "" }}
                  </p>
                  <p class="my-1">
                    <i class="icon-envelope mr-2 text-primary"></i
                    >{{ profile.phone || "" }}
                  </p>
                  <p class="my-1">
                    <i class="icon-pin mr-2 text-primary"></i>
                    {{ profile.country || "" }}
                  </p>
                  <p class="my-1">
                    <i class="icon-link mr-2 text-primary"></i>
                    {{ profile.site || "" }}
                  </p>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <SilentLogin
                class="float-sm-right"
                :client="profile"
                :site="profile.site"
              />
            </v-col>
          </v-row>
        </div>
      </div>

      <v-card class="mt-4 flat has-shadow shadow card">
        <v-card-title class="d-flex">
          <h4 class="mt-3">{{ profile.type_desc || "User" }} Orders</h4>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="$store.state.search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            dense
            hide-details
            class="my-auto"
          ></v-text-field>
        </v-card-title>
        <user-orders-table
          v-if="userType == userTypes.admin || userType == userTypes.client"
          :orders="orders"
        ></user-orders-table>
      </v-card>

      <v-card
        class="mt-4 flat has-shadow shadow card"
        v-if="profile && profile.id"
      >
        <Wallet :user_id="profile.id"></Wallet>
      </v-card>

      <!-- <div class="card has-shadow">
        <div class="card-body px-0"></div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import UserOrdersTable from "../../components/tables/UserOrdersTable.vue";
import SilentLogin from "../../components/SilentLogin.vue";
import Wallet from "./Wallet.vue";

export default {
  name: "WriterProfile",
  data: () => ({}),

  components: {
    UserOrdersTable,
    SilentLogin,
    Wallet,
  },

  computed: {
    ...mapState("writers", ["loading"]),
    ...mapGetters("writers", ["profile", "orders", "wallet_entires"]),
    ...mapGetters("auth", ["userType", "userTypes"]),
  },

  methods: {
    ...mapActions("writers", ["getWriter"]),
  },

  mounted() {
    this.getWriter(this.$route.params.id)
      .then((res) => {
        
        console.log(res)
      })
      .catch((err) => console.log(err));
  },
};
</script>
